export default {
  LOGIN_MODAL_OPEN: 'login_modal_open',
  LOGOUT: 'logout',
  LOGIN_SUCCESS: 'login_success',
  NAVIGATION: 'navigation',
  CUSTOMER_SUPPORT_START: 'customer_support_start',
  START_PJ: 'start_pj',
  SEEZ_BENEFITS_CLICKED: 'homepage_benefits_clicked',
  OUTBOUND_LINK_CLICK: 'outbound_link_click',
  CTA_CLICK: 'cta_click',
  CAR_TYPE_CLICKED: 'homepage_car_type_clicked',
  CAR_CLICK: 'car_click',
  SEARCH_RESULT: 'search_result',
  SHOP_BY_CAR_TYPE: 'home_page_shop_by_car_type_viewed',
  SEEZ_BENEFITS: 'home_page_benefits_viewed',
  BROWSE_BY_LIFESTYLE: 'home_page_browse_by_lifestyle_viewed',
  RECENTLY_ADDED_CARS: 'homepage_recently_added_cars_viewed',
  CAR_FINANCING: 'home_page_car_financing_viewed',
  TRYG_PARTNERSHIP: 'home_page_tryg_partnership_viewed',
  TRYG_PARTNERSHIP_SEARCH_ALL_CLICK: 'home_page_tryg_partnership_search_all_clicked',
  TRADE_IN_VALUATION: 'home_page_trade_in_valuation_viewed',
  SHOW_LOGIN: 'show_login',
  LOAD_INTRO_PAGE: 'load_intro_page',
  LISTING_DETAILS_BENEFITS_CLICK: 'listing_details_benefits_click',
  FAVOURITE_CAR: 'favourite_car',
  ADD_TO_CART: 'add_to_cart',
  LIVE_AGENT_BTN_CLICKED: 'liveagent_btn_clicked'
}
