export const CustomTypes = {
  BRAND_CONTENT: 'brand_content',
  AD_CAMPAIGN: 'ad_campaign',
  AD_CAMPAIGN_DEV: 'ad_campaign_dev'
}

Object.freeze(CustomTypes)

export const prismicApi = {
  methods: {
    handleLang(selectedLanguage) {
      const languageOptions = {
        en: 'en-gb',
        da: 'da-dk'
      }
      return languageOptions[selectedLanguage] ?? 'en-gb'
    },
    async getMasterRef(apiUrl) {
      const response = await fetch(apiUrl)
      const result = await response.json()
      return result.refs[0].ref
    },
    async queryByUid(type = CustomTypes.BRAND_CONTENT, uid = 'bmw', language) {
      const selectedLang = this.handleLang(language)
      const access_token = import.meta.env.VITE_PRISMIC_TOKEN
      const apiUrl = new URL(import.meta.env.VITE_PRISMIC_URL)
      const master_ref = await this.getMasterRef(apiUrl)

      const params = {
        page: 1,
        pageSize: 1,
        lang: selectedLang,
        access_token,
        ref: master_ref,
        q: `[[at(my.${type}.uid, "${uid}")]]`
      }

      const seachDocumentUrl = new URL(apiUrl + 'documents/search')

      Object.entries(params).map(item => seachDocumentUrl.searchParams.append(item[0], item[1]))

      const r = await fetch(seachDocumentUrl)
      const data = await r.json()

      if (data.errors) throw new Error('Error loading data from prismic cms')

      return data.results[0]
    }
  }
}
