<template>
  <div class="mainSiteHeader">
    <MainNav v-if="hoveringMainMenu" ref="mainNav" :hovering-main-menu="hoveringMainMenu" />
    <header ref="header" class="header-transparent">
      <div ref="mainMenu" :class="{ mainMenu: true, hovering: hoveringMainMenu }" data-test-id="header_main_menu">
        <button aria-label="Main menu" @click="hoveringMainMenu = !hoveringMainMenu">
          <CrossIcon v-if="hoveringMainMenu" />
          <HamburgerIcon v-if="!hoveringMainMenu" />
        </button>
      </div>
      <router-link to="/" :class="{ seezHomeLink: envLabel }" :data-env="envLabel" aria-label="Home Page" title="Seez" @click="trackNavigation('Home')">
        <Logo />
      </router-link>
      <NavDesktop />
      <div ref="userMenu" class="user" :class="{ userMenu: true, hovering: hoveringUserMenu }" data-test-id="header_user_menu">
        <div class="accountToogleWrapper">
          <div v-if="!isLoggedIn">{{ t['login'] }}</div>
          <div v-else class="authenticatedUserGreet" :title="`${t['hello_user_name']} ${userName}!`">
            {{ t['hello_user_name'] }}<span v-if="userName">{{ ' ' + userName?.split(' ')[0] }}</span
            >!
          </div>
          <button aria-label="User Menu" :aria-expanded="hoveringUserMenu" @click="hoveringUserMenu = !hoveringUserMenu">
            <UserIconStroke v-if="!isLoggedIn" class="userIcon" alt="Login" data-test-id="header_user_menu" />
            <UserIconFill v-else class="userIcon" alt="Login" data-test-id="header_user_menu" />
          </button>
        </div>
        <ul role="menu">
          <li v-if="!isLoggedIn && !loginDisabled" role="menuitem" data-test-id="login_button">
            <button aria-label="login" @click="login">
              <UserIconStroke style="width: 20px; height: 20px" alt="Login" />
              {{ t['login'] }}
            </button>
          </li>
          <li role="menuitem">
            <router-link :to="'/me/' + $root.localizedRoutes.Searches.path" data-test-id="my_searches" @click="trackNavigation('My Saved Searches')">
              <svg viewBox="0 0 14 14">
                <path d="M13.67 12.14 10.42 8.86C11.18 7.73 11.48 6.41 11.32 4.89 11.12 3.62 10.57 2.54 9.68 1.67 8.79.77 7.7.24 6.43.05 5.17-.09 4.03.11 3.01.66 2.01 1.22 1.22 2.01.66 3.01.11 4.03-.09 5.17.05 6.43.24 7.7.77 8.79 1.67 9.68 2.54 10.59 3.62 11.14 4.89 11.32 6.41 11.48 7.73 11.18 8.86 10.42L12.14 13.67C12.36 13.89 12.61 14 12.91 14 13.2 14 13.45 13.89 13.67 13.67 13.89 13.45 14 13.2 14 12.91 14 12.61 13.89 12.36 13.67 12.14ZM2.16 5.69C2.18 4.7 2.52 3.88 3.2 3.23 3.86 2.55 4.68 2.21 5.66 2.19 6.64 2.21 7.47 2.55 8.15 3.23 8.8 3.88 9.14 4.7 9.16 5.69 9.14 6.67 8.8 7.49 8.15 8.15 7.47 8.82 6.64 9.17 5.66 9.19 4.68 9.17 3.86 8.82 3.2 8.15 2.52 7.49 2.18 6.67 2.16 5.69Z" fill="white" />
              </svg>
              {{ t['my_searches'] }}
            </router-link>
          </li>
          <li role="menuitem">
            <router-link :to="'/me/' + $root.localizedRoutes.Favorites.path" data-test-id="my_favorites" @click="trackNavigation('My Favorites')">
              <svg viewBox="0 0 14 12.5">
                <path d="M12.91 6.56 7.63 12C7.45 12.18 7.24 12.28 7 12.28 6.76 12.28 6.55 12.18 6.37 12L1.09 6.56C.33 5.72-.04 4.76 0 3.66.04 2.59.48 1.66 1.34.87 2.12.23 2.99-.05 3.94.03 4.9.12 5.74.52 6.45 1.23L7 1.78 7.55 1.23C8.26.52 9.09.12 10.04.03 11-.05 11.88.23 12.66.87 13.52 1.66 13.96 2.59 14 3.66 14.04 4.74 13.67 5.7 12.91 6.56Z" fill="white" />
              </svg>
              {{ t['my_favorites'] }}
            </router-link>
          </li>
          <li v-if="isLoggedIn" role="menuitem">
            <router-link :to="'/me/' + $root.localizedRoutes.Tradeins.path" data-test-id="my_tradeins" @click="trackNavigation('My Tradeins')">
              <svg viewBox="0 0 20 16">
                <path d="M3.84375 2.80634L3.28125 4.46259H9.6875L9.125 2.80634C9.0625 2.61884 8.875 2.46259 8.65625 2.46259H4.3125C4.09375 2.46259 3.90625 2.61884 3.84375 2.80634ZM1.125 4.68134L1.9375 2.18134C2.28125 1.15009 3.25 0.462585 4.3125 0.462585H8.65625C9.71875 0.462585 10.6875 1.15009 11.0312 2.18134L11.7812 4.46259H11.3125C9.8125 4.46259 8.46875 5.43134 8 6.86884L7.28125 8.99384C7.09375 9.15009 6.90625 9.30634 6.75 9.46259H2.5V10.4626C2.5 11.0251 2.03125 11.4626 1.5 11.4626H1C0.4375 11.4626 0 11.0251 0 10.4626V9.46259V6.46259C0 5.68134 0.4375 4.99384 1.125 4.68134ZM1.75 6.96259C1.75 7.40009 2.0625 7.71259 2.5 7.71259C2.90625 7.71259 3.25 7.40009 3.25 6.96259C3.25 6.55634 2.90625 6.21259 2.5 6.21259C2.0625 6.21259 1.75 6.55634 1.75 6.96259ZM11.3125 7.46259C11.0938 7.46259 10.9062 7.61884 10.8438 7.80634L10.2812 9.46259H16.6875L16.125 7.80634C16.0625 7.61884 15.875 7.46259 15.6562 7.46259H11.3125ZM8.9375 7.18134C9.28125 6.15009 10.25 5.46259 11.3125 5.46259H15.6562C16.7188 5.46259 17.6875 6.15009 18.0312 7.18134L18.8438 9.68134C19.5312 9.99384 20 10.6813 20 11.4626V14.4626V15.4626C20 16.0251 19.5312 16.4626 19 16.4626H18.5C17.9375 16.4626 17.5 16.0251 17.5 15.4626V14.4626H9.5V15.4626C9.5 16.0251 9.03125 16.4626 8.5 16.4626H8C7.4375 16.4626 7 16.0251 7 15.4626V14.4626V11.4626C7 10.6813 7.4375 9.99384 8.125 9.68134L8.9375 7.18134ZM9.5 11.2126C9.0625 11.2126 8.75 11.5563 8.75 11.9626C8.75 12.4001 9.0625 12.7126 9.5 12.7126C9.90625 12.7126 10.25 12.4001 10.25 11.9626C10.25 11.5563 9.90625 11.2126 9.5 11.2126ZM16.75 11.9626C16.75 12.4001 17.0625 12.7126 17.5 12.7126C17.9062 12.7126 18.25 12.4001 18.25 11.9626C18.25 11.5563 17.9062 11.2126 17.5 11.2126C17.0625 11.2126 16.75 11.5563 16.75 11.9626Z" fill="white" />
              </svg>
              {{ t['my_tradeins'] }}
            </router-link>
          </li>
          <li v-if="isLoggedIn" role="menuitem">
            <router-link :to="'/me/' + $root.localizedRoutes.Orders.path" data-test-id="my_orders" @click="trackNavigation('My Orders')">
              <svg viewBox="0 0 16 14">
                <path d="M12.66 11.38C12.3 11.39 11.99 11.52 11.76 11.76 11.5 12.01 11.38 12.32 11.38 12.69 11.39 13.05 11.52 13.36 11.76 13.62 11.99 13.85 12.3 13.98 12.66 14 13.02 13.98 13.33 13.85 13.56 13.62 13.82 13.36 13.95 13.05 13.95 12.69 13.95 12.32 13.83 12.01 13.59 11.76 13.35 11.52 13.04 11.39 12.66 11.38ZM4.79 11.38C4.42 11.39 4.12 11.52 3.88 11.76 3.63 12.01 3.5 12.32 3.5 12.69 3.52 13.05 3.65 13.36 3.88 13.62 4.12 13.85 4.42 13.98 4.79 14 5.15 13.98 5.45 13.85 5.69 13.62 5.94 13.36 6.07 13.05 6.07 12.69 6.07 12.32 5.95 12.01 5.71 11.76 5.48 11.52 5.17 11.39 4.79 11.38ZM15.59 1.23C15.4.99 15.17.88 14.88.88H3.31L3.28.55C3.19.2 2.97.02 2.63-0H.63C.25.04.04.25-0 .63-0 .81.06.97.19 1.09.32 1.24.46 1.31.63 1.31H2.05L3.72 9.98C3.81 10.31 4.03 10.48 4.38 10.5H13.34C13.74 10.46 13.96 10.25 14 9.87 13.96 9.45 13.74 9.22 13.34 9.19H4.92L4.68 7.88H13.37C13.79 7.86 14.07 7.65 14.22 7.25L15.72 2.02C15.8 1.71 15.75 1.45 15.59 1.23Z" fill="white" />
              </svg>
              {{ t['my_orders'] }}
            </router-link>
          </li>
          <li v-if="isLoggedIn" role="menuitem">
            <router-link :to="'/me/' + $root.localizedRoutes.Account.path" data-test-id="header_profile_button" @click="trackNavigation('Account Details')">
              <svg viewBox="0 0 14 14">
                <path d="M13.67 9.08C13.65 8.91 13.57 8.79 13.43 8.7L12.17 7.96C12.22 7.63 12.25 7.31 12.25 7 12.25 6.69 12.22 6.37 12.17 6.04L13.43 5.3C13.57 5.21 13.64 5.09 13.64 4.92 13.64 4.78 13.54 4.48 13.34 4.05 13.14 3.63 12.91 3.23 12.63 2.84 12.36 2.44 12.12 2.23 11.92 2.21 11.83 2.21 11.75 2.23 11.68 2.27L10.42 3.01C9.93 2.59 9.37 2.27 8.75 2.05V.57C8.73.36 8.61.21 8.39.14 7.96.05 7.49 0 7 0 6.51 0 6.04.05 5.61.14 5.39.21 5.27.36 5.25.57V2.05C4.63 2.27 4.07 2.59 3.58 3.01L2.32 2.27C2.25 2.23 2.17 2.21 2.08 2.21 1.9 2.23 1.67 2.43 1.39 2.82 1.12 3.18.88 3.58.68 4.02.46 4.44.36 4.74.36 4.92.36 5.09.43 5.21.57 5.3L1.83 6.04C1.78 6.35 1.75 6.67 1.75 7 1.75 7.33 1.78 7.65 1.83 7.96L.57 8.7C.43 8.79.36 8.91.36 9.08.36 9.22.46 9.52.66 9.95.86 10.37 1.09 10.77 1.37 11.16 1.64 11.56 1.88 11.77 2.08 11.79 2.17 11.79 2.25 11.77 2.32 11.73L3.58 10.99C4.07 11.41 4.63 11.73 5.25 11.95V13.43C5.27 13.64 5.39 13.79 5.61 13.86 6.06 13.95 6.52 14 6.97 14 7.45 14 7.92 13.95 8.39 13.86 8.61 13.77 8.73 13.63 8.75 13.43V11.95C9.37 11.73 9.93 11.41 10.42 10.99L11.68 11.73C11.75 11.77 11.83 11.79 11.92 11.79 12.1 11.77 12.33 11.57 12.61 11.18 12.88 10.82 13.12 10.42 13.32 9.98 13.54 9.56 13.65 9.26 13.67 9.08ZM7 9.19C6.38 9.17 5.86 8.96 5.44 8.56 5.04 8.14 4.83 7.62 4.81 7 4.83 6.38 5.04 5.86 5.44 5.44 5.86 5.04 6.38 4.83 7 4.81 7.62 4.83 8.14 5.04 8.56 5.44 8.96 5.86 9.17 6.38 9.19 7 9.17 7.62 8.96 8.14 8.56 8.56 8.14 8.96 7.62 9.17 7 9.19Z" fill="white" />
              </svg>
              {{ t['account_settings'] }}
            </router-link>
          </li>
          <li v-if="isLoggedIn" data-test-id="header_logout_button" role="menuitem" @click="confirmLogout">
            <svg viewBox="0 0 14 12.4">
              <path d="M2.63 12.28H4.38C4.63 12.28 4.84 12.19 5 12.03 5.17 11.86 5.25 11.66 5.25 11.4 5.25 11.14 5.17 10.94 5 10.77 4.84 10.61 4.63 10.53 4.38 10.53H2.63C2.37 10.53 2.16 10.44 2 10.28 1.83 10.11 1.75 9.91 1.75 9.65V2.65C1.75 2.39 1.83 2.19 2 2.02 2.16 1.86 2.37 1.78 2.63 1.78H4.38C4.63 1.78 4.84 1.69 5 1.53 5.17 1.36 5.25 1.16 5.25.9 5.25.64 5.17.44 5 .27 4.84.11 4.63.03 4.38.03H2.63C1.88.04 1.26.3.77.79.27 1.28.02 1.9 0 2.65V9.65C.02 10.4.27 11.02.77 11.51 1.26 12 1.88 12.26 2.63 12.28ZM13.81 5.69 9.87 1.97C9.65 1.77 9.42 1.72 9.16 1.83 8.9 1.96 8.77 2.16 8.75 2.43V4.4H5.25C4.99 4.4 4.79 4.48 4.62 4.65 4.46 4.81 4.38 5.02 4.38 5.28V7.03C4.38 7.28 4.46 7.49 4.62 7.65 4.79 7.82 4.99 7.9 5.25 7.9H8.75V9.84C8.77 10.11 8.9 10.32 9.16 10.44 9.42 10.55 9.65 10.52 9.87 10.33L13.81 6.61C13.94 6.49 14 6.33 14 6.15 14 5.97 13.94 5.81 13.81 5.69Z" fill="white" />
            </svg>
            {{ t['logout'] }}
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import UserIconStroke from '../../assets/icons/icon-user-stroke.svg'
import UserIconFill from '../../assets/icons/icon-user-fill.svg'
import CrossIcon from '../../assets/icons/header/cross.svg'
import HamburgerIcon from '../../assets/icons/header/hamburger.svg'
import MainNav from '../../components/Header/MainNav.vue'
import NavDesktop from '../../components/Header/NavDesktop.vue'
import Logo from './Logo.vue'

export default {
  name: 'PageHeader',
  components: { NavDesktop, MainNav, UserIconStroke, UserIconFill, CrossIcon, HamburgerIcon, Logo },
  data() {
    return {
      loginDisabled: import.meta.env.VITE_DISABLE_LOGIN?.toLowerCase() === 'true',
      hoveringMainMenu: false,
      hoveringUserMenu: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    },
    envLabel() {
      return import.meta.env.VITE_ENV_LABEL
    },
    isLoggedIn() {
      return this.$root.user != null
    },
    userName() {
      const userName = this.$root?.user?.name
      const isEmail = this.checkIfEmail(userName)
      return !isEmail ? userName : null
    }
  },
  watch: {
    hoveringMainMenu(value) {
      const bodyStyles = document.body.style
      if (value) {
        bodyStyles.setProperty('overflow', 'hidden')
        bodyStyles.setProperty('height', '100vh')
        document.addEventListener('mousedown', this.handleClickOutsideMainMenu)
        window.addEventListener('blur', this.handleClickOutsideMainMenu)
        // this.$refs.header.classList.remove('header-transparent', 'header')
        this.$refs.header.classList.add('gradient-background')
      } else {
        bodyStyles.setProperty('overflow', 'overlay')
        bodyStyles.removeProperty('height')
        document.removeEventListener('mousedown', this.handleClickOutsideMainMenu)
        window.removeEventListener('blur', this.handleClickOutsideMainMenu)
        // this.$refs.header.classList.add('header-transparent', 'header')
        if (this?.$refs?.header) {
          this.$refs.header.classList.remove('gradient-background')
        }
        if (this?.$refs?.mainNav?.subMenuExpanded) {
          this.$refs.mainNav.subMenuExpanded = false
        }
      }
    },

    hoveringUserMenu(value) {
      if (value) {
        document.addEventListener('mousedown', this.handleClickOutsideUserMenu)
        window.addEventListener('blur', this.handleClickOutsideUserMenu)
      } else {
        document.removeEventListener('mousedown', this.handleClickOutsideUserMenu)
        window.removeEventListener('blur', this.handleClickOutsideUserMenu)
      }
    },
    $route() {
      this.hoveringMainMenu = false
      this.hoveringUserMenu = false
    }
  },
  methods: {
    checkIfEmail(text) {
      return text.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },
    handleClickOutsideMainMenu(event) {
      if (event.type === 'blur') this.hoveringMainMenu = false
      else if (!this.$refs.mainMenu.contains(event.target) && !this.$refs.mainNav.$el.contains(event.target)) this.hoveringMainMenu = false
    },
    handleClickOutsideUserMenu(event) {
      if (event.type === 'blur') this.hoveringUserMenu = false
      else if (!this.$refs.userMenu.contains(event.target)) this.hoveringUserMenu = false
    },
    handleClickOutsideExpandedSubMenu(event) {
      if (event.target.innerText === this.t.why_seez) return

      if (event.type === 'blur') {
        this.subMenuExpanded = !this.subMenuExpanded
      } else if (!this.$refs.seezSubMenu.contains(event.target)) {
        this.subMenuExpanded = !this.subMenuExpanded
      }
    },
    login() {
      window.seezSdk.showLogin(null, true, { branding: '#loginBranding' })

      const loginElement = document.querySelector('seez-sdk-login')
      loginElement.style.zIndex = 5
      // loginElement.addEventListener('track', e => this.$root.trackEvent(e))
    },
    confirmLogout() {
      window.seezSdk.showLogout()
    },
    trackSupport() {
      const userId = this.$root.user != null ? this.$root.user.id : ''
      this.$root.track('customer_support_start', { event_name: 'customer_support_start', event_category: 'user_engagment', event_action: 'customer_support_start', event_label: { user_id: userId } })
    },
    trackNavigation(name) {
      this.$root.track(this.$root.analyticsEvents.NAVIGATION, {
        event_name: this.$root.analyticsEvents.NAVIGATION,
        event_label: name,
        event_category: 'user_engagement',
        event_action: { iteraction: { navigation: { type: 'header_navigation' } } }
      })
    }
  }
}
</script>

<style lang="scss">
.userIcon {
  width: 24px;
  height: 24px;
  vertical-align: top;
  @media screen and (min-width: 60rem) {
    width: 26px;
    height: 26px;
  }
}
.accountToogleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.375rem;
  font-weight: 400;
  font-size: 1rem;
  @media screen and (min-width: 60rem) {
    gap: 0.938rem;
  }
}
.authenticatedUserGreet {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25vw;
  @media screen and (min-width: 60rem) {
    max-width: 10vw;
  }
}
.mainSiteHeader {
  header.header-transparent {
    background-color: transparent;
    position: absolute;
  }

  header.header-blue {
    background-color: #1a2634;
    position: sticky;
  }

  header.gradient-background {
    background: linear-gradient(111.12deg, #0068ff 35.04%, #9e80ff 105%);
  }

  header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    // justify-items: center;
    gap: 1.188rem;
    padding: 0.8em var(--content-side-padding-wide);
    color: white;
    // top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    > .mainMenu {
      display: none;
      width: 1.5em;
      text-align: center;

      > button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        margin: -4px;
        padding: 6px;
      }
    }

    > a {
      text-decoration: none;
      display: block;
      padding: 0.2em;
      width: 103px;
      position: relative;

      > img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }

      &.seezHomeLink::after {
        transform: rotate(-30deg);
        position: absolute;
        right: -2em;
        bottom: 0;
        text-decoration: none;
        color: white;
        background-color: red;
        font-size: 0.75em;
        padding: 0.25em 0.5em;
        border-radius: 1em;
        content: attr(data-env);
      }
    }

    > span {
      cursor: pointer;
    }

    @media screen and (max-width: 64rem) {
      #nav {
        display: flex;
        justify-content: center;
        width: 80%;
        font-size: 1em;
        gap: 1em;
        white-space: nowrap;

        a {
          color: white;
          text-decoration: none;
          padding: 0.25em 0.75em;
          border-radius: 0.25em;

          &:hover {
            color: #0068ff;
          }
        }
      }
    }

    @media screen and (max-width: 50rem) {
      .mainMenu {
        display: block;
      }

      #nav {
        display: none;
        position: fixed;
        top: 3.5em;
        left: 0;
        background-color: #1a2634;
        color: white;
        z-index: 3;
        width: 100%;
        height: calc(100% - 3.5em);

        &.whySeezSubMenuMobile {
          display: none;
        }

        &.hovering {
          display: flex;
          flex-flow: column;
          gap: 0;
        }

        > a {
          display: block;
          font-size: 1.25em;
          padding: 1em 4em 1em 1em;
          white-space: nowrap;
        }

        > a + a {
          border-top: 1px solid #48515d;
          border-radius: 0;
        }
      }
    }

    @media print {
      display: none;
    }
  }

  #nav {
    display: flex;
    justify-content: space-around;
    width: 70%;
    font-size: 1em;
    gap: 1em;
    white-space: nowrap;

    a {
      color: white;
      text-decoration: none;
      padding: 0.25em 0.75em;
      border-radius: 0.25em;

      .activeitem {
        font-weight: 800;
      }

      &:hover {
        text-shadow: 0 0 0 #fff;
        //color: #0068ff;
        border-radius: 0;
      }
    }
  }

  .userMenu {
    position: relative;
    overflow: visible;
    border-radius: 0.25em 0.25em 0 0;

    button {
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
    }

    &.hovering > ul {
      display: block;
    }

    > ul {
      z-index: 3;
      color: white;
      position: absolute;
      right: 0;
      top: 2.75em;
      display: none;
      background-color: #1a2634;
      min-width: calc(100% - 1em);
      margin: 0;
      padding: 0.5em;
      list-style-type: none;
      border-radius: 0.25em;
      white-space: nowrap;

      > li {
        padding: 0.5em 1em;
        border-radius: 0.25em;
        cursor: pointer;

        &:hover {
          background-color: hsla(0, 0%, 100%, 0.2);
        }

        > img {
          margin-right: 1em;
          height: 1em;
        }

        svg {
          margin-right: 0.75em;
          height: 1.25em;
          width: 1.25em;
          vertical-align: bottom;
        }

        > a,
        > button {
          background: none;
          border: none;
          text-align: left;
          text-decoration: none;
          color: white;
          padding: 0.5em 1em;
          margin: -0.5em -1em;
          display: inline-block;
          width: 100%;
          font-size: 1em;
          box-sizing: content-box;

          > * {
            vertical-align: text-bottom;
          }
        }
      }
    }
  }

  .active {
    font-weight: 700;
    // color: #0068ff !important;

    @media screen and (max-width: 50rem) {
      background-color: #0068ff !important;
      color: white !important;
    }
  }

  .modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(3px);
    display: grid;
    place-items: center;
    z-index: 3;

    > * {
      background-color: white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }

  .mobileModal {
    @media screen and (max-width: 32rem) {
      align-items: end !important;
      justify-items: center !important;
    }
  }
}
</style>
