<template>
  <div class="navigation" data-navigation>
    <router-link active-class="active" :to="'/' + $root.localizedRoutes.Search.path" @click="trackNavigation('Browse')">{{ t['browse'] }}</router-link>
    <router-link active-class="active" :to="'/' + $root.localizedRoutes.TradeIn.path" @click="trackNavigation('Trade-in')">{{ t['trade_in'] }}</router-link>
    <router-link active-class="active" :to="'/' + $root.localizedRoutes.Financing.path" @click="trackNavigation('Financing')">{{ t['financing'] }}</router-link>
    <router-link active-class="active" custom :to="'/' + $root.localizedRoutes.WhySeez.path">
      <span class="why-seez-text" @click.prevent="openSubMenu">{{ t['why_seez'] }}</span>
    </router-link>
    <a href="https://support.seez.dk" target="_blank" rel="noreferrer" @click="trackSupport()">{{ t['support'] }}</a>
    <a href="https://blog.seez.dk" target="_blank" rel="noreferrer" @click="trackNavigation('Blog')">Blog</a>
  </div>
</template>

<script>
export default {
  name: 'HeaderNavigation',
  props: {
    openSubMenu: {
      default: null,
      type: Function
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    trackSupport() {
      const userId = this.$root.user != null ? this.$root.user.id : ''
      this.$root.track(this.$root.analyticsEvents.CUSTOMER_SUPPORT_START, {
        event_name: this.$root.analyticsEvents.CUSTOMER_SUPPORT_START,
        event_category: 'user_engagment',
        event_action: this.$root.analyticsEvents.CUSTOMER_SUPPORT_START,
        event_label: { user_id: userId }
      })
    },
    trackNavigation(name) {
      this.$root.track(this.$root.analyticsEvents.NAVIGATION, {
        event_name: this.$root.analyticsEvents.NAVIGATION,
        event_label: name,
        event_category: 'user_engagement',
        event_action: { iteraction: { navigation: { type: 'header_navigation' } } }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../pages/variables';

.navigation {
  > a {
    color: $white;
    line-height: 1.25em;
  }
  > a:hover {
    font-weight: 500;
  }
}

.why-seez-text {
  cursor: pointer;
}
</style>
