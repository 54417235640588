import { createRouter, createWebHistory } from 'vue-router'
import Profile from '../pages/Profile/Profile.vue'
import Error404 from '../pages/Error404/Error404.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../pages/Home/Home.vue')
    },
    {
      path: '/me',
      name: 'Profile',
      component: Profile,
      children: [
        {
          path: 'favorites',
          name: 'Favorites',
          component: () => import(/* webpackChunkName: "profile" */ '../components/profile/Favorites.vue'),
          alias: ['favoritter']
        },
        {
          path: 'searches',
          name: 'Searches',
          component: () => import(/* webpackChunkName: "profile" */ '../components/profile/Searches.vue'),
          alias: ['mine-soegninger']
        },
        {
          path: 'orders',
          name: 'Orders',
          component: () => import(/* webpackChunkName: "profile" */ '../components/profile/Orders.vue'),
          meta: { requiresUser: true },
          alias: ['ordrer']
        },
        {
          path: 'tradeins',
          name: 'Tradeins',
          component: () => import(/* webpackChunkName: "profile" */ '../components/profile/Tradeins.vue'),
          meta: { requiresUser: true },
          alias: ['byttebil']
        },
        {
          path: 'account',
          name: 'Account',
          component: () => import(/* webpackChunkName: "profile" */ '../components/profile/Account.vue'),
          meta: { requiresUser: true },
          alias: ['konto']
        }
      ]
    },
    {
      path: '/start/:id',
      name: 'Intro',
      component: () => import(/* webpackChunkName: "intro" */ '../pages/JourneyIntro/JourneyIntro.vue')
    },
    {
      path: '/arrival/:id',
      name: 'Arrival',
      component: () => import(/* webpackChunkName: "intro" */ '../pages/JourneyIntro/JourneyIntro.vue'),
      alias: ['/ankomst/:id']
    },
    {
      path: '/external/:origin/:externalId',
      name: 'External',
      component: () => import(/* webpackChunkName: "intro" */ '../pages/JourneyIntro/JourneyIntro.vue'),
      alias: ['/ydre/:origin/:externalId']
    },
    {
      path: '/referred/:origin/:reference',
      name: 'Referred',
      component: () => import(/* webpackChunkName: "intro" */ '../pages/JourneyIntro/JourneyIntro.vue'),
      alias: ['/henvist/:origin/:reference']
    },
    {
      path: '/order/:id',
      name: 'Order',
      component: () => import(/* webpackChunkName: "intro" */ '../pages/Order/Order.vue'),
      alias: ['/ordrer/:id']
    },
    {
      path: '/search/:brand?/:model?',
      name: 'Search',
      component: () => import('../pages/Search/Search.vue'),
      alias: ['/find-brugt-bil/:brand?/:model?']
    },
    {
      path: '/listing/:id',
      name: 'Details',
      component: () => import('../pages/ListingDetails/ListingDetails.vue')
    },
    {
      path: '/test-checkout/:id',
      name: 'Checkout',
      component: () => import('../pages/Checkout/Checkout.vue')
    },
    {
      path: '/financing',
      name: 'Financing',
      component: () => import(/* webpackChunkName: "static" */ '../pages/Financing/Financing.vue'),
      alias: ['/billaan']
    },
    {
      path: '/tradein',
      name: 'TradeIn',
      component: () => import(/* webpackChunkName: "static" */ '../pages/TradeIn/TradeIn.vue'),
      alias: ['/byttebil', '/trade']
    },
    {
      path: '/why-seez',
      name: 'WhySeez',
      component: () => import(/* webpackChunkName: "static" */ '../pages/WhySeez/WhySeez.vue'),
      alias: ['/hvorfor-seez']
    },
    {
      path: '/about-seez',
      name: 'About Seez',
      component: () => import(/* webpackChunkName: "static" */ '../pages/AboutSeez/AboutSeez.vue'),
      alias: ['/om-seez']
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "legal" */ '../pages/Privacy/Privacy.vue'),
      alias: ['/privatlivspolitik']
    },
    {
      path: '/terms',
      name: 'Terms',
      component: () => import(/* webpackChunkName: "legal" */ '../pages/Terms/Terms.vue'),
      alias: ['/vilkaar-for-brug']
    },
    {
      path: '/quality',
      name: 'Quality',
      component: () => import(/* webpackChunkName: "benefits" */ '../pages/QualityAssured/QualityAssured.vue'),
      // component: () => import('../views/Quality'),
      alias: ['/kvalitet']
    },
    {
      path: '/delivery',
      name: 'Delivery',
      component: () => import(/* webpackChunkName: "benefits" */ '../pages/FreeDelivery/FreeDelivery.vue'),
      alias: ['/levering']
    },
    {
      path: '/return',
      name: 'Return',
      component: () => import(/* webpackChunkName: "benefits" */ '../pages/Return/Return.vue'),
      alias: ['/retur']
    },
    {
      path: '/warranty',
      name: 'Warranty',
      component: () => import(/* webpackChunkName: "benefits" */ '../pages/Warranty/Warranty.vue'),
      alias: ['/garanti']
    },
    {
      path: '/o/:listingId/:orderId?',
      redirect: to => ({
        path: '/start/:listingId',
        query: {
          orderId: to.params.orderId
        }
      })
    },
    {
      path: '/:pathMatch(.*)',
      //redirect: '/'
      component: Error404
    }
  ]
})

router.beforeEach((to, from, next) => {
  Object.defineProperty(document, 'referrer', {
    configurable: true,
    get: () => {
      return from.name ? window.location.origin + from.path : ''
    }
  })
  next()
})

router.afterEach(to => {
  if (router.app?.$root?.languageResources) {
    const localDetails = router.app.$root.localizedRoutes[to.name] ?? router.app.$root.localizedRoutes.Home
    router.app.setPageTitle(localDetails.title, localDetails.description)
    router.app.setCanonical()
  }
  window.seezSdk?.trackNavigation(window.location.href)
})

export default router
