<template>
  <div class="headerLogo">
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3005 680">
      <g>
        <path class="face" d="M1024.3,349.31v-.2c-.1-5.5,1.9-10.9,5.3-15.3l-.1-.1c27.8-35.1,44.4-79.5,44.4-127.7C1073.9,95.11,981.3,1.31,870.4,.01c-86.7-1-161.2,51.5-192.6,126.5-2.3,5.4-4.7,8.3-9.5,9.5-2.2,.5-4.4,.5-6.6,.1-34.4-6.3-76.4-11.3-124.2-11.4-48.3,0-90.6,5-125.3,11.4-2.2,.4-4.4,.4-6.6-.1-4.8-1.2-7.2-4.1-9.5-9.5C364.7,51.51,290.2-.99,203.5,.01,92.6,1.31,0,95.11,0,206.01c0,48.2,16.6,92.6,44.4,127.7l-.1,.1c3.4,4.3,5.4,9.7,5.3,15.3v.2c-.1,4.8-1.2,9.5-3.2,13.9-8.1,17.8-12.6,37.6-12.8,58.4-.9,80.3,64.2,146.8,144.4,147.7,14.5,.2,28.4-1.8,41.7-5.5,48.9-17,55.1-19.4,113.5-43.9,56.8-24.7,125.5-52.7,204.2-53.6h0c80.4,0,145.3,29.4,203.3,53.6,42.6,17.8,47.8,20.2,113.5,43.9,12.9,4.7,27.2,5.7,41.6,5.5,80.3-.9,145.3-67.4,144.4-147.7-.2-20.8-4.8-40.6-12.8-58.4-1.9-4.4-3-9.1-3.1-13.9Zm-817.4-24.7c-65.8,0-119.1-53.3-119.1-119.1s53.3-119.1,119.1-119.1,119.1,53.3,119.1,119.1-53.3,119.1-119.1,119.1Zm661.8,0c-65.8,0-119.1-53.3-119.1-119.1s53.3-119.1,119.1-119.1,119.1,53.3,119.1,119.1c-.1,65.8-53.4,119.1-119.1,119.1Z" />
        <circle ref="leftEye" class="left eye" cx="242" cy="158.41" r="41.4" :style="{ transform: 'rotate(' + left.angle + 'deg)' }" />
        <circle ref="rightEye" class="right eye" cx="903.7" cy="158.41" r="41.4" :style="{ transform: 'rotate(' + right.angle + 'deg)' }" />
        <path class="mouth" d="M537.5,489.27c-78.8,.8-147.5,28.8-204.2,53.6-49,20.56-61.26,25.56-92.82,36.68,78.35,48.29,184.18,79.56,295.72,79.56s218.18-31.57,296.6-80.14c-46.03-16.71-54.61-20.47-92.01-36.1-58-24.2-122.9-53.6-203.3-53.6Z" />
      </g>
      <g>
        <path class="letter" d="M1330.9,504.61c-20.1-15.7-23.6-44.7-7.9-64.8,15.7-20.1,44.7-23.6,64.8-7.9,27.7,21.8,72.1,35.2,109.5,36,38.3,.9,90.4-11.6,91.4-46.7,1-37.5-36.8-39.7-98.3-45.7-107.8-10.5-184.4-36.6-181.6-140,2.4-91.3,90.6-139.9,189.6-136.5,56.1,1.9,117.6,22,160.4,55.6,20.1,15.7,23.6,44.7,7.9,64.8-15.7,20.1-44.7,23.6-64.8,7.9-28.2-22.2-67.4-34.1-103.5-35.9-43.2-2.1-96.4,11.5-97.4,46.5-1,37.5,36.7,39.7,98.3,45.7,107.8,10.5,184.3,36.6,181.6,140-2.4,89.5-85.8,137.3-183.6,136.4-60.2-.5-123.8-22-166.4-55.4Z" />
        <path class="letter" d="M1708.3,334.21c0-130.4,99.5-235.5,221.6-235.5s215.3,98,221.3,223.2c.6,12.6-4,24.9-12.7,34.1-8.7,9.1-20.8,14.3-33.4,14.3h-296.9c14.6,56.2,68,97.6,130.9,97.6,39,0,76.6-16.1,102.9-44.2,17.4-18.6,46.6-19.7,65.2-2.3,18.6,17.4,19.7,46.6,2.3,65.2-43.6,46.7-106.1,73.5-170.4,73.5-126.7,.1-230.8-100.3-230.8-225.9Zm338.1-56.2c-17.8-50.9-63.4-87-116.4-87s-98.6,36.6-116.3,87h232.7Z" />
        <path class="letter" d="M2168.9,334.21c0-130.4,99.5-235.5,221.6-235.5s215.3,98,221.3,223.2c.6,12.6-4,24.9-12.7,34.1-8.7,9.1-20.8,14.3-33.4,14.3h-296.9c14.6,56.2,68,97.6,130.9,97.6,39,0,76.6-16.1,102.9-44.2,17.4-18.6,46.6-19.7,65.2-2.3,18.6,17.4,19.7,46.6,2.3,65.2-43.6,46.7-106.1,73.5-170.4,73.5-126.8,.1-230.8-100.3-230.8-225.9Zm338-56.2c-17.8-50.9-63.4-87-116.4-87s-98.6,36.6-116.3,87h232.7Z" />
        <path class="letter" d="M2636.6,533.21c-6.7-16.5-4.2-35.8,6.4-49.4l229.1-292.8h-197.8c-22.9,0-41.5-20.7-41.5-46.1s18.6-46.1,41.5-46.1h288.9c16.3,0,31,10.6,37.8,27,6.7,16.5,4.2,35.8-6.4,49.4l-229.1,292.8h197.8c22.9,0,41.5,20.7,41.5,46.1s-18.6,46.1-41.5,46.1h-288.9c-16.3,0-31-10.5-37.8-27Z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'HeaderLogo',
  props: {
    googly: { type: Boolean, default: false }
  },
  data() {
    return {
      left: { x: 0, y: 0, angle: 0 },
      right: { x: 0, y: 0, angle: 0 }
    }
  },
  watch: {
    googly(v) {
      document.removeEventListener('pointermove', this.updateEyes)
      if (v) document.addEventListener('pointermove', this.updateEyes)
    }
  },
  mounted() {
    const leftR = this.$refs.leftEye.getBoundingClientRect()
    this.left.x = (leftR.right + leftR.left) / 2
    this.left.y = (leftR.bottom + leftR.top) / 2
    const rightR = this.$refs.rightEye.getBoundingClientRect()
    this.right.x = (rightR.right + rightR.left) / 2
    this.right.y = (rightR.bottom + rightR.top) / 2

    if (this.googly) document.addEventListener('pointermove', this.updateEyes)
  },
  beforeUnmount() {
    document.removeEventListener('pointermove', this.updateEyes)
  },
  methods: {
    updateEyes(e) {
      const p = { x: e.pageX, y: e.pageY }
      this.left.angle = Math.round((Math.atan2(p.y - this.left.y, p.x - this.left.x) * 180) / Math.PI) + 50
      this.right.angle = Math.round((Math.atan2(p.y - this.right.y, p.x - this.right.x) * 180) / Math.PI) + 50
    }
  }
}
</script>

<style lang="scss">
.headerLogo {
  display: grid;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;

  > svg {
    .face,
    .eye,
    .letter {
      fill: white;
    }

    .mouth {
      fill: #99c3ff;
      transform: translateY(-1.3em);
    }

    .left.eye {
      transform-origin: 7% 30%;
    }

    .right.eye {
      transform-origin: 29% 30%;
    }
  }
}
</style>
