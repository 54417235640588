<template>
  <div class="offline">
    <Logo :googly="true" color="black" />
    <h1>Site is under maintenance</h1>
    <p>We are working on making the experience better for you. We will be back soon.</p>
  </div>
</template>
<script>
import Logo from './Header/Logo.vue'
export default {
  name: 'SiteOffline',
  components: { Logo }
}
</script>
<style lang="scss">
.offline {
  height: calc(100% - 30vmin);
  padding: 15vmin 5vmin;
  display: grid;
  gap: 2vmin;
  grid-template-rows: 1fr auto auto;
  place-items: center;
  place-content: center;
  text-align: center;

  > h1,
  p {
    margin: 0;
    color: black;
  }

  .headerLogo {
    width: 70vmin;

    .face,
    .eye,
    .letter {
      fill: black;
    }
  }
}
</style>
