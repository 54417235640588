<template>
  <div class="navigationWrapper" :class="{ showNavigation: hoveringMainMenu }">
    <div class="navigationOverlay" />
    <div class="navigationSlider" :class="{ showSubMenu: subMenuExpanded }">
      <div class="navigation">
        <router-link active-class="active" :to="'/' + $root.localizedRoutes.Search.path" @click="trackNavigation('Browse')">{{ t['browse'] }}<Bullet /></router-link>
        <router-link active-class="active" :to="'/' + $root.localizedRoutes.TradeIn.path" @click="trackNavigation('Trade-in')"> {{ t['trade_in'] }}<Bullet /> </router-link>
        <router-link active-class="active" :to="'/' + $root.localizedRoutes.Financing.path" @click="trackNavigation('Financing')">{{ t['financing'] }}<Bullet /></router-link>
        <button :class="{ active: isWhyPath }" @click="subMenuExpanded = !subMenuExpanded">
          <span class="why-text">
            {{ t['why_seez'] }}
          </span>
          <Bullet />
        </button>
        <a href="https://support.seez.dk" target="_blank" rel="noreferrer" @click="trackSupport()">{{ t['support'] }}<Bullet /></a>
        <a href="https://blog.seez.dk" target="_blank" rel="noreferrer" @click="trackNavigation('Blog')">Blog<Bullet /></a>
        <ContactUsMenuMobile />
        <router-link class="termsAndConditions" :to="'/' + $root.localizedRoutes.Terms.path">Terms and Conditions</router-link>
      </div>
      <WhySeezSubMenuMobile :close-menu="closeMenu" />
    </div>
  </div>
</template>

<script>
import ContactUsMenuMobile from '../../components/Header/ContactUsMenuMobile.vue'
import WhySeezSubMenuMobile from '../../components/Header/WhySeezSubMenuMobile.vue'
import Bullet from '../../assets/icons/arrow-right.svg'

export default {
  name: 'MainNav',
  components: { WhySeezSubMenuMobile, Bullet, ContactUsMenuMobile },
  props: {
    hoveringMainMenu: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      subMenuExpanded: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    },
    isWhyPath() {
      if (this.$root.$route.fullPath === `/${this.$root.localizedRoutes.WhySeez.path}`) return true
      return false
    }
  },
  methods: {
    trackSupport() {
      const userId = this.$root.user != null ? this.$root.user.id : ''
      this.$root.track('customer_support_start', { event_name: 'customer_support_start', event_category: 'user_engagment', event_action: 'customer_support_start', event_label: { user_id: userId } })
    },
    trackNavigation(name) {
      this.$root.track(this.$root.analyticsEvents.NAVIGATION, {
        event_name: this.$root.analyticsEvents.NAVIGATION,
        event_label: name,
        event_category: 'user_engagement',
        event_action: { iteraction: { navigation: { type: 'header_navigation' } } }
      })
    },
    closeMenu() {
      this.subMenuExpanded = !this.subMenuExpanded
    }
  }
}
</script>

<style lang="scss" scoped>
.navigationWrapper {
  position: absolute;
  top: 3.5em;
  height: calc(100% - 3.5em);
  z-index: 3;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.navigationSlider {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: 200%;
  height: 100%;
  transition: transform 0.3s ease-in-out;

  &.showSubMenu {
    transform: translateX(-50%);
  }
}

.navigation {
  background-color: #1a2634;

  @media screen and (max-width: 50rem) {
    display: flex;
    flex-direction: column;
    overflow-y: overlay;
  }

  > button {
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: start;
    border-top: 1px solid #48515d;
    border-bottom: 1px solid #48515d;
  }

  > a,
  button {
    display: block;
    font-size: 1.25em;
    padding: 1em 4em 1em 1em;
    white-space: nowrap;
    color: #fff;
    position: relative;

    > svg {
      position: absolute;
      right: 1em;
      top: 1.25em;
    }
  }

  > a + a {
    border-top: 1px solid #48515d;
    border-radius: 0;
  }

  > .termsAndConditions {
    display: none;

    @media screen and (max-width: 50rem) {
      display: flex;
      margin-top: auto;
      margin-bottom: 3em;
      margin-left: 0.313rem;
      font-size: 0.75em;
      border-top: none;
    }
  }
}

.showNavigation {
  transform: translateX(0);
}
</style>
