<template>
  <footer class="footer">
    <svg width="69" height="20" viewBox="0 0 69 20" fill="#F2F2F2">
      <path d="M.92 17.26C.11 16.62-.03 15.46.6 14.65 1.24 13.84 2.41 13.7 3.22 14.32 4.34 15.2 6.14 15.74 7.65 15.78 9.2 15.82 11.33 15.31 11.37 13.89 11.4 12.37 9.88 12.29 7.38 12.05 3 11.63-.11 10.57 0 6.39.1 2.69 3.68.73 7.69.88 9.96.95 12.47 1.77 14.19 3.13 15 3.77 15.15 4.93 14.52 5.74 13.88 6.55 12.71 6.69 11.9 6.07 10.75 5.16 9.17 4.69 7.7 4.62 5.95 4.54 3.79 5.09 3.75 6.5 3.71 8.02 5.24 8.1 7.74 8.34 12.12 8.76 15.22 9.82 15.11 14 15.02 17.61 11.63 19.55 7.67 19.51 5.22 19.48 2.65 18.61.92 17.26Z" />
      <path d="M16.24 10.38C16.24 5.1 20.28.86 25.23.86 30.02.86 33.97 4.83 34.21 9.88 34.24 10.39 34.06 10.89 33.69 11.25 33.34 11.63 32.85 11.83 32.34 11.83H20.29C20.88 14.11 23.04 15.78 25.59 15.78 27.18 15.78 28.7 15.13 29.76 14 30.47 13.24 31.65 13.2 32.41 13.9 33.17 14.6 33.21 15.78 32.51 16.54 30.74 18.43 28.21 19.51 25.59 19.51 20.46 19.51 16.24 15.46 16.24 10.38ZM29.95 8.11C29.22 6.05 27.37 4.6 25.22 4.6 23.06 4.6 21.22 6.08 20.51 8.11H29.95Z" />
      <path d="M34.91 10.38C34.91 5.1 38.95.86 43.91.86 48.69.86 52.65 4.83 52.89 9.88 52.91 10.39 52.73 10.89 52.37 11.25 52.02 11.63 51.52 11.83 51.02 11.83H38.98C39.57 14.11 41.73 15.78 44.28 15.78 45.87 15.78 47.39 15.13 48.45 14 49.15 13.24 50.34 13.2 51.1 13.9 51.86 14.6 51.9 15.78 51.2 16.54 49.43 18.43 46.89 19.51 44.28 19.51 39.14 19.51 34.91 15.46 34.91 10.38ZM48.63 8.11C47.91 6.05 46.06 4.6 43.91 4.6 41.74 4.6 39.91 6.08 39.19 8.11H48.63Z" />
      <path d="M53.89 18.42C53.61 17.75 53.72 16.97 54.16 16.42L63.45 4.58H55.43C54.5 4.58 53.75 3.75 53.75 2.72 53.75 1.68 54.5.85 55.43.85H67.15C67.82.85 68.41 1.27 68.69 1.95 68.96 2.61 68.86 3.39 68.42 3.95L59.13 15.78H67.15C68.08 15.78 68.83 16.61 68.83 17.65 68.83 18.68 68.08 19.51 67.15 19.51H55.43C54.76 19.51 54.17 19.08 53.89 18.42Z" />
    </svg>
    <nav class="social">
      <a href="https://www.instagram.com/seez.dk/" target="_blank" rel="noreferrer" aria-label="Instagram" @click="trackEvent($root.analyticsEvents.OUTBOUND_LINK_CLICK, { name: 'Instagram' })">
        <svg width="19" height="18" viewBox="0 0 19 18" fill="white">
          <path d="M9.5 4.39C6.81 4.39 4.63 6.45 4.63 9 4.63 11.55 6.81 13.62 9.5 13.62 12.2 13.62 14.37 11.55 14.37 9 14.37 6.45 12.2 4.39 9.5 4.39ZM9.5 12C7.76 12 6.34 10.65 6.34 9 6.34 7.35 7.76 6 9.5 6 11.25 6 12.67 7.35 12.67 9 12.67 10.65 11.24 12 9.5 12ZM15.71 4.2C15.71 4.79 15.2 5.27 14.57 5.27 13.94 5.27 13.44 4.79 13.44 4.2 13.44 3.6 13.94 3.12 14.57 3.12 15.2 3.12 15.71 3.6 15.71 4.2ZM18.93 5.29C18.86 3.85 18.51 2.57 17.4 1.52 16.29.46 14.94.14 13.42.06 11.85-.02 7.15-.02 5.58.06 4.06.13 2.72.46 1.6 1.51.49 2.57.14 3.84.07 5.28-.02 6.77-.02 11.23.07 12.71.14 14.15.49 15.43 1.6 16.48 2.72 17.54 4.06 17.86 5.58 17.94 7.15 18.02 11.85 18.02 13.42 17.94 14.94 17.87 16.29 17.54 17.4 16.48 18.51 15.43 18.86 14.15 18.93 12.71 19.02 11.23 19.02 6.77 18.93 5.29ZM16.91 14.31C16.58 15.09 15.94 15.7 15.1 16.02 13.85 16.49 10.88 16.38 9.5 16.38 8.12 16.38 5.15 16.48 3.9 16.02 3.07 15.7 2.43 15.1 2.1 14.31 1.6 13.12 1.72 10.31 1.72 9 1.72 7.69 1.61 4.88 2.1 3.69 2.43 2.91 3.07 2.3 3.9 1.98 5.15 1.51 8.12 1.62 9.5 1.62 10.88 1.62 13.86 1.52 15.1 1.98 15.93 2.3 16.57 2.9 16.91 3.69 17.4 4.88 17.29 7.69 17.29 9 17.29 10.31 17.4 13.13 16.91 14.31Z" />
        </svg>
      </a>
      <a href="https://linkedin.com/company/seez-denmark/" target="_blank" rel="noreferrer" aria-label="LinkedIn" @click="trackEvent($root.analyticsEvents.OUTBOUND_LINK_CLICK, { name: 'Linkedin' })">
        <svg width="19" height="18" viewBox="0 0 19 18" fill="white">
          <path d="M17.64 0H1.35C.61 0 0 .58 0 1.3V16.7C0 17.42.61 18 1.35 18H17.64C18.39 18 19 17.42 19 16.7V1.3C19 .58 18.39 0 17.64 0ZM5.74 15.43H2.93V6.84H5.75V15.43H5.74ZM4.33 5.67C3.43 5.67 2.7 4.97 2.7 4.12 2.7 3.27 3.43 2.57 4.33 2.57 5.23 2.57 5.97 3.27 5.97 4.12 5.97 4.97 5.24 5.67 4.33 5.67ZM16.3 15.43H13.48V11.25C13.48 10.25 13.46 8.97 12.02 8.97 10.55 8.97 10.33 10.06 10.33 11.18V15.43H7.51V6.84H10.21V8.01H10.25C10.63 7.34 11.55 6.63 12.92 6.63 15.77 6.63 16.3 8.41 16.3 10.72V15.43Z" />
        </svg>
      </a>
      <a href="https://www.youtube.com/channel/UC0MDYtgUBi02-6tqhWgSGBw" target="_blank" rel="noreferrer" aria-label="YouTube" @click="trackEvent($root.analyticsEvents.OUTBOUND_LINK_CLICK, { name: 'Youtube' })">
        <svg width="28" height="18" viewBox="0 0 28 18" fill="white">
          <path d="M27.19 2.75C26.88 1.66 25.94.81 24.74.52 22.58 0 13.9 0 13.9 0 13.9 0 5.22 0 3.05.52 1.86.81.92 1.66.6 2.75.02 4.7.02 8.79.02 8.79.02 8.79.02 12.88.6 14.84.92 15.92 1.86 16.73 3.05 17.02 5.22 17.55 13.9 17.55 13.9 17.55 13.9 17.55 22.58 17.55 24.74 17.02 25.94 16.73 26.88 15.92 27.19 14.84 27.77 12.88 27.77 8.79 27.77 8.79 27.77 8.79 27.77 4.7 27.19 2.75ZM11.06 12.5V5.08L18.31 8.79 11.06 12.5Z" />
        </svg>
      </a>
    </nav>
    <p class="dark">
      Kontakt os: Tlf.: +45 80 82 08 01 (alle dage 10:00 - 18:00)
      <br />
      &copy; Seez ApS 2022 - <span>{{ t['all_rights_reserved'] }}</span>
      <br />
      CVR: 42847682
    </p>

    <nav class="about">
      <router-link :to="'/' + $root.localizedRoutes.About.path" @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'About us' })">{{ t['about_us'] }}</router-link>
      <router-link :to="'/' + $root.localizedRoutes.Financing.path" @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'About financing' })">{{ t['about_financing'] }}</router-link>
      <router-link :to="'/' + $root.localizedRoutes.TradeIn.path" @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'About trade-in' })">{{ t['about_trade_in'] }}</router-link>
    </nav>
    <nav class="contact">
      <a href="https://support.seez.dk" target="_blank" rel="noreferrer" @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Support' })">{{ t['support'] }}</a>
      <a href="mailto:bug@seez.dk" @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Report a Bug' })">{{ t['report_bug'] }}</a>
      <a href="https://seez.co/da/onboarding" target="_blank" @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Become a Dealer' })">
        {{ t['become_a_dealer'] }}
        <ArrowTopRightIcon />
      </a>
    </nav>
    <nav class="legal">
      <router-link :to="'/' + $root.localizedRoutes.Privacy.path" @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Privacy Policy' })">{{ t['privacy_policy'] }}</router-link>
      <router-link :to="'/' + $root.localizedRoutes.Terms.path" @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Terms and Conditions' })">
        {{ t['terms_and_conditions'] }}
      </router-link>
      <span>&nbsp;</span>
      <span v-if="multilanguage" class="languages">
        <button @click="setLanguage('en')">English</button>
        <button @click="setLanguage('da')">Danish</button>
      </span>
      <div />
    </nav>
  </footer>
</template>

<script>
import ArrowTopRightIcon from '../assets/icons/arrow-top-right.svg'

export default {
  name: 'PageFooter',
  components: { ArrowTopRightIcon },
  computed: {
    t() {
      return this.$root.languageResources.FOOTER_COMPONENT_TRANSLATIONS
    },
    multilanguage() {
      return import.meta.env.VITE_MULTILANGUAGE !== 'false'
    }
  },
  methods: {
    trackEvent(eventName, properties) {
      const userId = this.$root.user != null ? this.$root.user.id : ''
      if (properties.name === 'Support') {
        this.$root.track(this.$root.analyticsEvents.CUSTOMER_SUPPORT_START, {
          event_name: this.$root.analyticsEvents.CUSTOMER_SUPPORT_START,
          event_category: 'user_engagment',
          event_action: this.$root.analyticsEvents.CUSTOMER_SUPPORT_START,
          event_label: { user_id: userId }
        })
      }
      return this.$root.track(eventName, {
        event_name: eventName,
        event_label: properties.name,
        event_category: 'user_engagement',
        event_action: {
          iteraction: {
            navigation: {
              type: 'footer_navigation'
            }
          }
        }
      })
    },
    setLanguage(lang) {
      localStorage.setItem('language', lang)
      this.$root.language = lang
      this.$root.setLanguage()
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  color: var(--background);
  background-color: #1a2634;
  display: grid;
  padding: 3em var(--content-side-padding-wide) 1em;
  grid-template-columns: 5fr 3fr 3fr 2fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'logo about contact legal'
    'social about contact legal'
    'copy about contact legal';
  gap: 1em;
  align-items: end;

  a {
    color: var(--background);
  }

  .dark {
    grid-area: copy;
    color: #999;
    margin: 0;
    font-size: small;
    line-height: 1.4em;
  }

  .bold {
    font-weight: bold;
  }

  > nav {
    > p {
      text-transform: uppercase;
      font-weight: bold;
    }
    > a {
      display: block;
      margin: 1em 0;
    }
  }

  .social {
    grid-area: social;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    > a {
      margin: 0;
    }
  }

  > img {
    grid-area: logo;
  }

  .about {
    grid-area: about;
    align-self: start;
  }

  .contact {
    grid-area: contact;
  }

  .legal {
    grid-area: legal;
    align-self: start;
  }

  .languages {
    display: flex;
    gap: 1em;

    > button {
      border: none;
      background: none;
      cursor: pointer;
      color: white;
      padding: 0;
      font-family: 'Biennale', sans-serif;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 50rem) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    padding: 3em var(--content-side-padding) 5em;
    grid-template-areas:
      'logo empty'
      'about contact'
      'legal social'
      'copy copy';
    gap: 1em 3em;
    align-items: start;

    > nav > a {
      margin: 0.5em 0;
    }

    > .social {
      gap: 1em 2em;
      justify-content: space-evenly;
      align-self: center;
    }

    > .dark {
      justify-self: center;
    }
  }

  @media print {
    display: none;
  }
}
</style>
