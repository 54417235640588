<template>
  <div class="errorPage">
    <TitleContainer>
      <h1>{{ t['error'] }}</h1>
      <p>{{ t['page_not_found'] }}</p>
    </TitleContainer>
    <div>&nbsp;</div>
    <PageFooter />
  </div>
</template>

<script>
import TitleContainer from '../../pages/TitleContainer.vue'
import PageFooter from '../../components/Footer.vue'

export default {
  name: 'Error404NotFound',
  components: { TitleContainer, PageFooter },
  computed: {
    t() {
      return this.$root.languageResources.ERROR_VIEW_TRANSLATIONS
    }
  }
}
</script>

<style lang="scss">
.errorPage {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
}
</style>
