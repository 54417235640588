<template>
  <div class="profile">
    <title-container />
    <div class="profile-panel">
      <span>{{ t['my_account'] }}</span>
      <nav>
        <router-link :to="'/me/' + $root.localizedRoutes.Searches.path" @click="track('My Searches')">
          <svg viewBox="0 0 16 16">
            <path d="M11.5 6.49C11.5 4.71 10.53 3.09 9 2.19 7.44 1.28 5.53 1.28 4 2.19 2.44 3.09 1.5 4.71 1.5 6.49 1.5 8.3 2.44 9.93 4 10.83 5.53 11.73 7.44 11.73 9 10.83 10.53 9.93 11.5 8.3 11.5 6.49ZM10.53 11.61C9.41 12.48 8 12.98 6.5 12.98 2.91 12.98 0 10.08 0 6.49 0 2.94 2.91 0 6.5 0 10.06 0 13 2.94 13 6.49 13 8.02 12.47 9.43 11.59 10.55L15.78 14.7C16.06 15.01 16.06 15.48 15.78 15.76 15.47 16.07 15 16.07 14.72 15.76L10.53 11.61Z" />
          </svg>
          {{ t['my_searches'] }}
        </router-link>
        <router-link :to="'/me/' + $root.localizedRoutes.Favorites.path" @click="track('My Favorites')">
          <svg viewBox="0 0 16 13.6">
            <path d="M7.03 13.32 6.97 13.23 1.5 8.17C.53 7.26 0 6.01 0 4.7V4.61C0 2.42 1.56.51 3.72.11 4.94-.14 6.19.14 7.22.86 7.5 1.04 7.75 1.29 8 1.54 8.13 1.42 8.25 1.26 8.41 1.14 8.53 1.04 8.66.95 8.78.86 9.78.14 11.03-.14 12.25.11 14.44.51 16 2.42 16 4.61V4.7C16 6.01 15.44 7.26 14.47 8.17L9 13.23 8.94 13.32C8.69 13.54 8.34 13.7 8 13.7 7.63 13.7 7.31 13.54 7.03 13.32ZM7.47 3.2C7.44 3.2 7.44 3.2 7.44 3.17L6.88 2.54C6.16 1.73 5.06 1.39 4 1.57 2.53 1.86 1.5 3.14 1.5 4.61V4.7C1.5 5.61 1.84 6.45 2.5 7.04L8 12.14 13.47 7.04C14.13 6.45 14.5 5.61 14.5 4.7V4.61C14.5 3.14 13.44 1.86 11.97 1.57 10.91 1.39 9.81 1.73 9.09 2.54L8.53 3.17C8.53 3.2 8.53 3.2 8.5 3.23 8.38 3.36 8.19 3.45 8 3.45 7.78 3.45 7.59 3.36 7.47 3.23V3.2Z" />
          </svg>
          {{ t['my_favorites'] }}
        </router-link>
        <router-link v-if="$root.user" :to="'/me/' + $root.localizedRoutes.Tradeins.path" @click="track('My Tradeins')">
          <svg viewBox="0 0 20 16">
            <path d="M3.84 2.35 3.28 4H9.69L9.13 2.35C9.06 2.16 8.88 2 8.66 2H4.31C4.09 2 3.91 2.16 3.84 2.35ZM1.13 4.22 1.94 1.72C2.28.69 3.25 0 4.31 0H8.66C9.72 0 10.69.69 11.03 1.72L11.78 4H11.31C9.81 4 8.47 4.97 8 6.41L7.28 8.53C7.09 8.69 6.91 8.85 6.75 9H2.5V10C2.5 10.57 2.03 11 1.5 11H1C.44 11 0 10.57 0 10V9 6C0 5.22.44 4.53 1.13 4.22ZM1.75 6.5C1.75 6.94 2.06 7.25 2.5 7.25 2.91 7.25 3.25 6.94 3.25 6.5 3.25 6.1 2.91 5.75 2.5 5.75 2.06 5.75 1.75 6.1 1.75 6.5ZM11.31 7C11.09 7 10.91 7.16 10.84 7.35L10.28 9H16.69L16.13 7.35C16.06 7.16 15.88 7 15.66 7H11.31ZM8.94 6.72C9.28 5.69 10.25 5 11.31 5H15.66C16.72 5 17.69 5.69 18.03 6.72L18.84 9.22C19.53 9.53 20 10.22 20 11V14 15C20 15.57 19.53 16 19 16H18.5C17.94 16 17.5 15.57 17.5 15V14H9.5V15C9.5 15.57 9.03 16 8.5 16H8C7.44 16 7 15.57 7 15V14 11C7 10.22 7.44 9.53 8.13 9.22L8.94 6.72ZM9.5 10.75C9.06 10.75 8.75 11.1 8.75 11.5 8.75 11.94 9.06 12.25 9.5 12.25 9.91 12.25 10.25 11.94 10.25 11.5 10.25 11.1 9.91 10.75 9.5 10.75ZM16.75 11.5C16.75 11.94 17.06 12.25 17.5 12.25 17.91 12.25 18.25 11.94 18.25 11.5 18.25 11.1 17.91 10.75 17.5 10.75 17.06 10.75 16.75 11.1 16.75 11.5Z" />
          </svg>
          {{ t['my_tradeins'] }}
        </router-link>
        <router-link v-if="$root.user" :to="'/me/' + $root.localizedRoutes.Orders.path" @click="track('My Orders')">
          <svg viewBox="0 0 18 16">
            <path d="M.75 0H2.16C2.84 0 3.47.41 3.75 1H16.59C17.41 1 18 1.78 17.78 2.59L16.5 7.34C16.25 8.34 15.34 9 14.34 9H5.31L5.5 9.91C5.56 10.25 5.88 10.5 6.22 10.5H15.25C15.66 10.5 16 10.84 16 11.25 16 11.69 15.66 12 15.25 12H6.22C5.16 12 4.22 11.25 4.03 10.19L2.41 1.72C2.38 1.59 2.28 1.5 2.16 1.5H.75C.31 1.5 0 1.19 0 .75 0 .34.31 0 .75 0ZM4.09 2.5 5.03 7.5H14.34C14.69 7.5 14.97 7.28 15.06 6.97L16.25 2.5H4.09ZM5.5 16C4.94 16 4.47 15.72 4.19 15.25 3.91 14.81 3.91 14.22 4.19 13.75 4.47 13.31 4.94 13 5.5 13 6.03 13 6.5 13.31 6.78 13.75 7.06 14.22 7.06 14.81 6.78 15.25 6.5 15.72 6.03 16 5.5 16ZM16 14.5C16 15.06 15.69 15.53 15.25 15.81 14.78 16.09 14.19 16.09 13.75 15.81 13.28 15.53 13 15.06 13 14.5 13 13.97 13.28 13.5 13.75 13.22 14.19 12.94 14.78 12.94 15.25 13.22 15.69 13.5 16 13.97 16 14.5Z" />
          </svg>
          {{ t['my_orders'] }}
        </router-link>
        <router-link v-if="$root.user" :to="'/me/' + $root.localizedRoutes.Account.path" @click="track('Account Settings')">
          <svg viewBox="0 0 16 16">
            <path d="M12.7 12.5C13.83 11.34 14.52 9.75 14.52 8 14.52 4.44 11.58 1.5 8.02 1.5 4.42 1.5 1.52 4.44 1.52 8 1.52 9.75 2.17 11.34 3.3 12.5 3.89 11.03 5.33 10 7.02 10H9.02C10.67 10 12.11 11.03 12.7 12.5ZM11.45 13.53C11.23 12.38 10.2 11.5 9.02 11.5H7.02C5.8 11.5 4.77 12.38 4.55 13.53 5.55 14.16 6.73 14.5 8.02 14.5 9.27 14.5 10.45 14.16 11.45 13.53ZM8.02 16C5.14 16 2.52 14.5 1.08 12-.36 9.53-.36 6.5 1.08 4 2.52 1.53 5.14 0 8.02 0 10.86 0 13.48 1.53 14.92 4 16.36 6.5 16.36 9.53 14.92 12 13.48 14.5 10.86 16 8.02 16ZM8.02 7.5C8.45 7.5 8.86 7.28 9.08 6.88 9.3 6.5 9.3 6.03 9.08 5.63 8.86 5.25 8.45 5 8.02 5 7.55 5 7.14 5.25 6.92 5.63 6.7 6.03 6.7 6.5 6.92 6.88 7.14 7.28 7.55 7.5 8.02 7.5ZM5.27 6.25C5.27 5.28 5.77 4.38 6.64 3.88 7.48 3.41 8.52 3.41 9.39 3.88 10.23 4.38 10.77 5.28 10.77 6.25 10.77 7.25 10.23 8.16 9.39 8.66 8.52 9.13 7.48 9.13 6.64 8.66 5.77 8.16 5.27 7.25 5.27 6.25Z" />
          </svg>
          {{ t['account_settings'] }}
        </router-link>
        <button v-if="$root.user" @click="logout">
          <svg viewBox="0 0 16 14">
            <path d="M11 3.59V5.25C11 5.69 10.66 6 10.25 6H6.5V8H10.25C10.66 8 11 8.34 11 8.75V10.44L14.44 7 11 3.59ZM16 7C16 7.38 15.84 7.72 15.59 7.97L11.97 11.59C11.69 11.88 11.31 12 10.94 12 10.13 12 9.5 11.38 9.5 10.56V9.5H6.5C5.66 9.5 5 8.84 5 8V6C5 5.19 5.66 4.5 6.5 4.5H9.5V3.47C9.5 2.66 10.13 2 10.94 2 11.31 2 11.69 2.16 11.97 2.44L15.59 6.06C15.84 6.31 16 6.66 16 7ZM5.25 1.5H2.75C2.03 1.5 1.5 2.06 1.5 2.75V11.25C1.5 11.97 2.03 12.5 2.75 12.5H5.25C5.66 12.5 6 12.84 6 13.25 6 13.69 5.66 14 5.25 14H2.75C1.22 14 0 12.78 0 11.25V2.75C0 1.25 1.22 0 2.75 0H5.25C5.66 0 6 .34 6 .75 6 1.19 5.66 1.5 5.25 1.5Z" />
          </svg>
          {{ t['logout'] }}
        </button>
      </nav>
    </div>
    <router-view class="profileSection" />
  </div>
</template>

<script>
import TitleContainer from '../../pages/TitleContainer.vue'

export default {
  name: 'ProfilePage',
  components: { TitleContainer },
  computed: {
    t() {
      return this.$root.languageResources.PROFILE_VIEW_TRANSLATIONS
    },
    enabledFeature() {
      return import.meta.env.VITE_ENV_LABEL
    }
  },
  methods: {
    logout() {
      window.seezSdk.showLogout()
    },
    track(name) {
      this.$root.trackCTA(this.$root.analyticsEvents.CTA_CLICK, { name: name, type: 'Sidebar - My Profile', category: 'user_engagement' })
    }
  }
}
</script>

<style lang="scss">
.profile-panel {
  border-right: 1px solid #efefef;
  display: grid;
  padding-right: 1em;
  align-content: flex-start;

  > span {
    grid-area: 1 / 1;
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 1em 0.5em 0;
    text-align: left;
    color: #333333;
  }

  > nav {
    grid-area: 2 / 1;
    display: grid;
    align-self: start;
    gap: 0.25em;

    > a {
      padding: 1em 2em;
      color: var(--font1);
      text-decoration: none;
      position: relative;
      border-radius: 2em;
      display: flex;
      gap: 1em;
      align-items: center;

      &.router-link-exact-active {
        background-color: #cce1ff;
      }

      > svg {
        height: 1em;
        fill: var(--highlight);
      }
    }

    > button {
      border: none;
      background: none;
      padding: 1em 2em;
      text-align: left;
      cursor: pointer;
      color: #ff0009;
      display: flex;
      gap: 1em;
      align-items: center;
      font-family: 'Biennale', sans-serif;
      font-size: 1em;

      > svg {
        height: 1em;
        fill: #ff0009;
      }
    }
  }

  @media screen and (max-width: 57rem) {
    display: none;
  }
}

.profile {
  padding: 0 var(--content-side-padding-wide);
  position: relative;
  display: grid;
  grid-template-columns: 24ch auto;
  grid-template-rows: auto 1fr;
  column-gap: 1em;
  text-align: left;

  > .titleContainer {
    grid-column: 1 / -1;
    margin-inline: calc(var(--content-side-padding-wide) * -1);
  }

  > span {
    grid-area: 1 / 1;
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.25em 1em;
    text-align: left;
    color: #333333;
  }

  > nav {
    grid-area: 2 / 1;
    display: grid;
    align-self: start;
    gap: 0.25em;

    > a {
      padding: 1em 2em;
      color: var(--font1);
      text-decoration: none;
      position: relative;
      border-radius: 2em;
      display: flex;
      gap: 1em;
      align-items: center;

      &.router-link-exact-active {
        background-color: #cce1ff;
      }
    }

    > button {
      border: none;
      background: none;
      padding: 1em 2em;
      text-align: left;
      cursor: pointer;
      color: #ff0009;
      display: flex;
      gap: 1em;
      align-items: center;
      font-family: 'Biennale', sans-serif;
      font-size: 1em;
    }
  }

  > .profileSection {
    grid-area: 2 / 2 / span 2;
    place-self: start stretch;

    > h1 {
      text-align: left;
      font-size: 1.5em;
      margin: 1em 0 0 0.5em;
      color: black;
    }
  }

  @media screen and (max-width: 57rem) {
    box-sizing: border-box;
    width: 100%;
    padding: 0 1rem;
    position: relative;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 0;
    text-align: left;
    overflow-x: hidden;

    > .profileSection {
      grid-area: 2;
      place-self: start stretch;
    }
  }
}
</style>
