<template>
  <div v-if="subMenuExpanded" class="whySeezSubMenu">
    <section class="whyBuyFromSeez">
      <h2>{{ t['why_buy_from_seez'] }}</h2>
      <p>{{ t['our_aim'] }}</p>
      <nav>
        <div>
          <router-link :to="`/${$root.localizedRoutes.About.path}`">
            <span class="hover-underline-animation">
              {{ t['read_more_about_seez'] }}
              <svg viewBox="0 0 5.5 9" width="6" height="10">
                <path d="M.67 8.99C.49 8.99.33 8.93.21 8.81-.05 8.57-.05 8.15.21 7.91L3.59 4.51.21 1.13C-.05.89-.05.47.21.23.45-.03.87-.03 1.11.23L4.95 4.07C5.21 4.31 5.21 4.73 4.95 4.97L1.11 8.81C.99 8.93.83 8.99.67 8.99Z" fill="#0068FF" />
              </svg>
            </span>
          </router-link>
        </div>
        <div>
          <router-link :to="`/${$root.localizedRoutes.WhySeez.path}`">
            <span class="hover-underline-animation">
              {{ t['how_it_works'] }}
              <svg width="6" height="10" viewBox="0 0 5.5 9">
                <path d="M.67 8.99C.49 8.99.33 8.93.21 8.81-.05 8.57-.05 8.15.21 7.91L3.59 4.51.21 1.13C-.05.89-.05.47.21.23.45-.03.87-.03 1.11.23L4.95 4.07C5.21 4.31 5.21 4.73 4.95 4.97L1.11 8.81C.99 8.93.83 8.99.67 8.99Z" fill="#0068FF" />
              </svg>
            </span>
          </router-link>
        </div>
      </nav>
      <!-- Become a Seez reseller section -->
      <p class="divider" />
      <h2>{{ t['seez_dealer_title'] }}</h2>
      <p>{{ t['seez_dealer_text'] }}.</p>
      <nav>
        <a href="https://seezoto.com/danmark" target="_blank">
          <span class="hover-underline-animation">
            {{ t['seez_dealer_learn_more'] }}
            <svg width="11" height="11" viewBox="0 0 10 10">
              <path d="M9.98 1V7.96C9.98 8.52 9.52 8.96 8.99 8.96 8.43 8.96 7.99 8.52 7.99 7.96V3.42L1.71 9.67C1.52 9.86 1.28 9.95 1.03 9.95.75 9.95.5 9.86.31 9.67-.09 9.3-.09 8.65.31 8.27L6.56 1.99H2.02C1.46 1.99 1.03 1.55 1.03 1 1.03.47 1.46 0 2.02 0H8.99C9.52 0 9.98.47 9.98 1Z" fill="#0068FF" />
            </svg>
          </span>
        </a>
      </nav>
      <!-- END Become a Seez dealer section -->
    </section>
    <section class="seezBenefitsWrapper">
      <div class="seezBenefits">
        <h2>{{ t['seez_benefits'] }}</h2>
        <nav>
          <!--Quality-->
          <router-link :to="`/${$root.localizedRoutes.Quality.path}`" class="seezBenefitsSection" @click="trackNavigation('Quality')">
            <QualityIcon />
            <div class="titleAndSubtitle">
              <h4 class="hover-underline-animation">{{ t['quality_asured_title'] }}</h4>
              <p>{{ t['quality_asured_text'] }}</p>
            </div>
            <ChevronIcon />
          </router-link>
          <router-link :to="`/${$root.localizedRoutes.Return.path}`" class="seezBenefitsSection" @click="trackNavigation('Return')">
            <MoneyBackGuaranteeIcon />
            <div class="titleAndSubtitle">
              <h4 class="hover-underline-animation">{{ t['return_title'] }}</h4>
              <p>{{ t['return_text'] }}</p>
            </div>
            <ChevronIcon />
          </router-link>
          <router-link :to="`/${$root.localizedRoutes.Warranty.path}`" class="seezBenefitsSection" @click="trackNavigation('Warranty')">
            <OneYearWarranty />
            <div class="titleAndSubtitle">
              <h4 class="hover-underline-animation">{{ t['warranty_title'] }}</h4>
              <p>{{ t['warranty_text'] }}</p>
            </div>
            <ChevronIcon />
          </router-link>
          <router-link :to="`/${$root.localizedRoutes.Delivery.path}`" class="seezBenefitsSection" @click="trackNavigation('Free Delivery')">
            <FreeDelivery />
            <div class="titleAndSubtitle">
              <h4 class="hover-underline-animation">{{ t['free_delivery_title'] }}</h4>
              <p>{{ t['free_delivery_text'] }}</p>
            </div>
            <ChevronIcon />
          </router-link>
        </nav>
      </div>
    </section>
  </div>
</template>

<script>
import QualityIcon from '../../assets/why-seez-submenu/quality.svg'
import MoneyBackGuaranteeIcon from '../../assets/why-seez-submenu/money-back-gaurantee.svg'
import OneYearWarranty from '../../assets/why-seez-submenu/1-year-warranty.svg'
import FreeDelivery from '../../assets/why-seez-submenu/free-delivery.svg'
import ChevronIcon from '../../assets/icons/chevron-black.svg'

export default {
  name: 'WhySeezSubMenu',
  components: { QualityIcon, MoneyBackGuaranteeIcon, OneYearWarranty, FreeDelivery, ChevronIcon },
  props: {
    subMenuExpanded: {
      default: false,
      type: Boolean
    },
    subMenuOpened: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    trackNavigation(name) {
      this.$root.track(this.$root.analyticsEvents.NAVIGATION, {
        event_name: this.$root.analyticsEvents.NAVIGATION,
        event_label: name,
        event_category: 'user_engagement',
        event_action: { iteraction: { navigation: { type: 'header_navigation' } } }
      })
    }
  }
}
</script>

<style lang="scss">
.whySeezSubMenu {
  position: absolute;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.4);
  top: 4em;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: var(--max-content-width-slim);
  display: flex;
  border-radius: 1rem;
  z-index: 4;
  max-width: 90vw;

  &::before {
    content: '';
    position: absolute;
    background-color: #0068ff;
    border-radius: 1px 1px 0px 0px;
    height: 2px;
    width: 6rem;
    top: -2px;
    z-index: 5;
    left: calc(50% + 3.5em);
  }

  @media screen and (max-width: 50rem) {
    display: none;
  }

  .whyBuyFromSeez {
    background-color: white;
    div:last-child {
      margin-top: 14px;
    }

    nav {
      margin-top: 1.5em;

      .hover-underline-animation::after {
        content: '';
        position: relative;
        display: block;
        height: 1.5px;
        width: 40%;
        bottom: 0;
        left: 0;
        background-color: #0068ff;
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }

      a {
        color: #0068ff;
        font-size: 1rem;
        font-weight: 600;
        &:hover {
          .hover-underline-animation::after {
            transform: scaleX(1);
          }
        }
      }
    }
    svg {
      margin-left: 0.625rem;
    }

    p {
      margin-top: 0.75rem;
      color: black;
      font-size: 0.8rem;
    }
    h2 {
      font-size: 1.1rem;
      margin: 0;
      color: black;
    }
    border-radius: 1rem 0 0 1rem;
    padding: 2.5rem 3.5rem 0 3rem;
    flex: 50%;

    .divider {
      border-top: 1px solid #eeeeee;
      margin: 2rem 0;
    }
  }
  .seezBenefitsWrapper {
    background-color: white;
    border-radius: 0 1rem 1rem 0;
    .seezBenefits {
      background-color: rgba(204, 225, 255, 0.2);
      border-radius: 0 1rem 1rem 0;
      flex: 50%;
      padding: 2.5rem 3rem;
      h2 {
        margin: 0;
        font-size: 1.12rem;
        color: black;
      }
    }
  }
  .seezBenefitsSection {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 1.2em;
    align-items: center;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    color: black;
    min-height: 4em;

    .hover-underline-animation {
      display: inline-block;
      position: relative;
    }

    .hover-underline-animation:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1.5px;
      bottom: 0;
      left: 0;
      background-color: black;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover {
      .hover-underline-animation:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .titleAndSubTitle {
      margin-bottom: 1.25rem;
    }
    h4 {
      margin-bottom: 0;
      margin-top: -6px;
    }

    p {
      margin-top: 0.37rem;
      font-size: 12.8px;
    }
  }
}
</style>
