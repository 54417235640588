<template>
  <div :class="{ titleContainer: true, empty: $slots.default == null }">
    <BgIcon class="bgIcon" />
    <slot />
  </div>
</template>

<script>
import BgIcon from '../assets/bg/bgIcon.svg'
export default {
  name: 'TitleContainer',
  components: { BgIcon }
}
</script>

<style lang="scss">
.titleContainer {
  position: relative;
  background: linear-gradient(147.27deg, #0068ff 23.48%, #9e80ff 92.92%);
  border-radius: 0 0 1.5rem 1.5rem;
  padding: 7.5em var(--content-side-padding) 4em;
  color: white;
  overflow: hidden;

  &.empty {
    padding: 3.5em var(--content-side-padding) 0;
    border-radius: 0;

    > .bgIcon {
      display: none;
    }
  }

  h1 {
    font-size: 2.5rem;
    margin: 1rem 0;
  }

  p {
    font-size: 1.25em;
  }

  > .bgIcon {
    height: 62rem;
    position: absolute;
    inset: -14.5rem calc(var(--content-side-padding) - 76.5rem) auto auto;
  }

  @media print {
    background: none;
    color: black;
    padding: 0 2rem;

    > .bgIcon {
      display: none;
    }
  }
}
</style>
