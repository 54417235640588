<template>
  <div class="navigation">
    <Navigation :open-sub-menu="toggleMenu" />
    <WhySeezSubMenu ref="seezSubMenu" :sub-menu-expanded="menuOpened" :sub-menu-opened="menuOpened" />
  </div>
</template>

<script>
import Navigation from '../../components/Header/Navigation.vue'
import WhySeezSubMenu from '../../components/Header/WhySeezSubMenu.vue'

export default {
  name: 'NavDesktop',
  components: { Navigation, WhySeezSubMenu },
  data() {
    return {
      menuOpened: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    }
  },
  watch: {
    menuOpened(value) {
      if (value) {
        document.addEventListener('mousedown', this.handleClickOutsideExpandedSubMenu)
        window.addEventListener('blur', this.handleClickOutsideExpandedSubMenu)
      } else {
        document.removeEventListener('mousedown', this.handleClickOutsideExpandedSubMenu)
        window.removeEventListener('blur', this.handleClickOutsideExpandedSubMenu)
      }
    },
    $route() {
      this.menuOpened = false
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened
    },
    handleClickOutsideExpandedSubMenu(event) {
      if (event.target.innerText === this.t.why_seez) return

      if (event.type === 'blur') {
        this.menuOpened = !this.menuOpened
      } else if (!this.$refs.seezSubMenu.$el.contains(event.target)) {
        this.menuOpened = !this.menuOpened
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../pages/variables';
.navigation {
  display: none;
  justify-self: stretch;
  padding: 0 1em;

  @media screen and (min-width: 50rem) {
    display: block;
  }

  [data-navigation] {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 24px;
    max-width: 46em;
    margin: auto;
  }
}
</style>
