<template>
  <div class="contactUsMenuMobile">
    <span class="sectionTitle">{{ t['contact_us'] }}</span>
    <ul class="list">
      <li>
        <a href="tel:+45 80 82 08 01">
          <PhoneIcon class="icon" />
          <span>+45 80 82 08 01</span>
        </a>
      </li>
      <li>
        <a href="mailto:support@seez.dk">
          <EmailIcon class="icon" />
          <span>support@seez.dk</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import PhoneIcon from '../../assets/icons/contact-us-menu-mobile/phone.svg'
import EmailIcon from '../../assets/icons/contact-us-menu-mobile/email.svg'

export default {
  name: 'ContactUsMenuMobile',
  components: { EmailIcon, PhoneIcon },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    }
  }
}
</script>

<style lang="scss">
//Global
.contactUsMenuMobile {
  display: none;
}

//Mobile
@media screen and (max-width: 50rem) {
  .contactUsMenuMobile {
    display: block;
    padding-left: 1.25rem;
    padding-top: 2.188rem;
    border-top: 0.75px solid #48515d;

    .sectionTitle {
      display: block;
      margin-bottom: 1.188rem;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.375rem;
      color: #fff;
    }

    .list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.188rem;
      color: #fff;

      li {
        padding-bottom: 1rem;
      }

      a {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        line-height: 1.188rem;
        text-decoration: underline;
        color: currentColor;
      }

      .icon {
        margin-right: 1.125rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
</style>
